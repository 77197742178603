var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-row',[_c('el-button',{staticClass:"integration-add-widget",staticStyle:{"height":"33px !important"},attrs:{"size":"mini"},on:{"click":function($event){return _vm.openQuickIntegrationAdd()}}},[_c('i',{staticClass:"el-icon-circle-plus",staticStyle:{"color":"white"}})]),_c('dialog-component',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"dialogRef",staticClass:"el-dialog el-dialog-fullscreen",attrs:{"title":'Add New ' + this.parentData.label + `'s Data`,"visible":_vm.quickAddIntegrationDialogVisible,"width":_vm.getIsMobile ? '100%' : '30%',"close-on-press-escape":false,"element-loading-text":"Adding Data...","fullscreen":true,"containerWidth":'40%'},on:{"before-close":_vm.handleClose}},[_c('div',[[_c('el-form',{ref:"integrationForm",attrs:{"label-position":"left","model":_vm.parentForm}},_vm._l((_vm.fieldsToUpdate),function(field,index){return _c('el-form-item',{key:index,attrs:{"label":field.label,"prop":field.key,"rules":field.isRequired
                  ? [
                      {
                        required: true,
                        message: `${field.label} is required`,
                      },
                    ]
                  : []}},[(field.type === 'select')?_c('el-select',{style:({ ..._vm.getStyle, width: '60%' }),attrs:{"placeholder":'Select ' + field.label,"clearable":true},model:{value:(_vm.parentForm[field.key]),callback:function ($$v) {_vm.$set(_vm.parentForm, field.key, $$v)},expression:"parentForm[field.key]"}},_vm._l((_vm.requiredData),function(option,index){return _c('el-option',{key:index,attrs:{"label":option.label,"value":option.value}})}),1):(field.type === 'text')?_c('el-input',{style:({ ..._vm.getStyle, width: '60%' }),attrs:{"placeholder":field.label,"clearable":true},model:{value:(_vm.parentForm[field.key]),callback:function ($$v) {_vm.$set(_vm.parentForm, field.key, $$v)},expression:"parentForm[field.key]"}}):_vm._e()],1)}),1)]],2),_c('span',{staticClass:"dialog-footer float-right",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("Cancel")]),[_c('el-button',{staticClass:"scaling-button",attrs:{"loading":_vm.loading},on:{"click":_vm.AddNewIntegrationData}},[_vm._v(_vm._s("Add ")+" "+_vm._s(this.parentData.label))])]],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }